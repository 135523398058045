import React from 'react'
import useTranslation from '@/hooks/useTranslation'
import { useVaultsData } from '@/hooks/useVaultsData'
import { useAuth } from '@/providers/Auth'
import { DataTableTemplate } from '@/templates/index'
import { Table } from '@/organisms/index'
import {
  EmptyStateTable,
  FilterDropdown,
  ModalActionVault,
  ModalConfirm
} from '@/molecules/index'
import { Typography } from '@/atoms/index'
import { VaultItemType } from '@/hooks/api/ecm/useVaultApi/useVaultApi.types'
import { FiltersKeyVaults } from '@/hooks/useVaultsData/useVaultsData.types'
import { Button } from '@/ui/atoms/shadcn'

const VaultsPage: React.FC = () => {
  const { t, isReady } = useTranslation('vaults')
  const { isAdmin, userContext } = useAuth()

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    optionsUsers,
    onSubmitActionModal,
    handleActionModal,
    filters,
    modalActionVault,
    setModalActionVault,
    isLoadingModalConfirm,
    handleApplyFilters,
    preparedFilters,
    setPreparedFilters,
    isLoadingSubmitModalAction
  } = useVaultsData({
    id: null,
    type: 'vaultList'
  })

  const breadcrumbs = [
    { name: t?.breadcrumbs?.manage, href: null, current: false },
    { name: t?.breadcrumbs?.vaults, href: '/vaults', current: true }
  ]

  return (
    <DataTableTemplate
      header={
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-2 sm:justify-between sm:items-center">
          <div className="flex flex-col flex-1 w-full gap-1">
            <Typography
              variant="title-2xl-medium"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-40"
              showSkeleton={!isReady}
              type="h1"
            >
              {t?.title}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="w-full text-gray-700 whitespace-no-wrap dark:text-gray-700"
              classNameSkeleton="w-60"
              showSkeleton={!isReady}
              type="p"
            >
              {t?.description}
            </Typography>
          </div>
          <div className="flex flex-row items-end justify-end gap-2">
            <FilterDropdown
              filtersSchema={[
                {
                  id: FiltersKeyVaults.USER,
                  type: 'select',
                  label: t?.selectLabelUser,
                  initialValue: userContext?.userAccountInformation?.id,
                  options: optionsUsers,
                  showSkeleton: !isReady,
                  hidden: !isAdmin,
                  isActive: Boolean(
                    isAdmin &&
                      !!filters?.user &&
                      filters?.user !== userContext?.userAccountInformation?.id
                  )
                },
                {
                  id: FiltersKeyVaults.QUERY,
                  type: 'inputSearch',
                  label: t?.filterQuery?.label,
                  placeholder: t?.filterQuery?.placeholder,
                  initialValue: '',
                  isActive: Boolean(filters?.query),
                  showSkeleton: !isReady
                },
                {
                  id: FiltersKeyVaults.TYPE_FAVORITE,
                  type: 'filterFavorites',
                  label: t?.filterFavoritesLabel,
                  showSkeleton: !isReady,
                  isActive: Boolean(filters?.typeFavorite)
                }
              ]}
              values={{
                user:
                  (preparedFilters?.user as string) ||
                  userContext?.userAccountInformation?.id,
                query: preparedFilters?.query || '',
                typeFavorite: preparedFilters?.typeFavorite as
                  | 'favorite'
                  | 'unfavorite'
              }}
              setPreparedValues={setPreparedFilters}
              onFilter={(filters) => {
                handleApplyFilters({
                  user:
                    filters?.user === userContext?.userAccountInformation?.id
                      ? null
                      : filters?.user,
                  query: filters?.query as string,
                  typeFavorite: filters?.typeFavorite as
                    | 'favorite'
                    | 'unfavorite'
                })
              }}
              showSkeleton={!isReady}
            />
            <Button
              variant="secondary"
              onClick={() => {
                setModalActionVault({
                  isOpen: true,
                  action: 'createVault',
                  id: null
                })
              }}
              className="w-full px-6 lg:px-6 md:w-auto"
              showSkeleton={!isReady}
              timeoutSkeleton={500}
            >
              {t?.buttonCreate}
            </Button>
          </div>
        </div>
      }
      breadcrumbs={breadcrumbs}
      showSkeleton={!isReady}
    >
      {currentDataTable?.isLoading ||
      (currentDataTable?.isSuccess &&
        currentDataTable?.data &&
        currentDataTable?.data?.items?.length > 0) ? (
        <div className="flex items-center justify-center">
          <Table<VaultItemType>
            columns={columns}
            data={currentDataTable?.data?.items || []}
            fetchData={setPageNumber}
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentDataTable?.data?.pagination &&
                currentDataTable?.data?.pagination?.totalItems /
                  currentDataTable?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            pagination={currentDataTable?.data?.pagination}
            isLoading={currentDataTable?.isLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
          />
        </div>
      ) : (
        <EmptyStateTable type="vaults" />
      )}
      {modalActionVault?.action !== 'deleteVault' &&
        modalActionVault.isOpen && (
          <ModalActionVault
            id={modalActionVault.id as string}
            isOpen={modalActionVault.isOpen}
            action={modalActionVault.action}
            onClose={() =>
              setModalActionVault({ isOpen: false, action: null, id: null })
            }
            setDataModalActionVault={setModalActionVault}
            onSubmit={onSubmitActionModal}
            canBeRemoved={
              currentDataTable?.data?.items?.find(
                (item) => item?.vaultId === modalActionVault.id
              )?.canBeRemoved
            }
            isLoading={isLoadingSubmitModalAction}
          />
        )}
      {modalActionVault?.action === 'deleteVault' &&
        modalActionVault.isOpen && (
          <ModalConfirm
            isOpen={
              modalActionVault.isOpen &&
              modalActionVault.action === 'deleteVault'
            }
            onClose={() =>
              setModalActionVault({
                isOpen: false,
                action: null,
                id: null
              })
            }
            title={t?.modalConfirmDeleteVault?.title}
            description={t?.modalConfirmDeleteVault?.description}
            confirmButtonText={t?.modalConfirmDeleteVault?.buttonConfirm}
            cancelButtonText={t?.modalConfirmDeleteVault?.buttonCancel}
            onConfirm={handleActionModal}
            loadingSubmit={isLoadingModalConfirm}
          />
        )}
    </DataTableTemplate>
  )
}

export default VaultsPage
