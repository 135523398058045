import { useState, useMemo, useCallback, useEffect } from 'react'
import {
  EllipsisVerticalIcon,
  InformationCircleIcon,
  StarIcon as StarIconOutline
} from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { CgSpinner } from 'react-icons/cg'
import { formatData } from '@/utils/date'
import { useAuth } from '@/providers/Auth'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Dropdown, type MenuItem, Typography } from '@/atoms/index'
import {
  CurrentVaultType,
  DataModalActionVaultType,
  FiltersKeyVaults,
  FiltersVaultsType,
  FlattenVaultItem,
  UseVaultsDataResponse,
  VaultFormType,
  VaultIdData
} from './useVaultsData.types'
import { useEnvelopeApi } from '../api/ecm/useEnvelopeApi'
import { EnvelopeStatus } from '../api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import useTemplateApi from '../api/ecm/useTemplatesApi/useTemplatesApi'
import { useCreateOptionsUser } from '../useCreateOptionsUser'
import { ModalErrorPublishEnvelopeType } from '../useEnvelopesData/useEnvelopesData.types'
import useLocalStorage from '../useLocalStorage'
import { useTable } from '../useTable'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import useVaultApi from '@/hooks/api/ecm/useVaultApi/useVaultApi'
import {
  CreateVaultRequest,
  VaultItem,
  VaultItemType
} from '@/hooks/api/ecm/useVaultApi/useVaultApi.types'
import useTableFilters from '@/hooks/useTableFilters/index'
import { cn } from '@/lib/utils'
import { showToast } from '@/ui/atoms/index'
import { type CurrentVaultId } from '@/ui/atoms/SelectVault/SelectVault'
import { Badge } from '@/ui/atoms/shadcn'
import { AvatarGroup, BadgeAvatarGroup } from '@/ui/molecules'

const flattenData = (data: VaultItem[] | undefined): any => {
  return data?.map((item) => {
    if (item.type === 'Envelope' && item.envelope !== null) {
      return { ...item.envelope, type: item.type }
    }
    if (item.type === 'Template' && item.template !== null) {
      return { ...item.template, type: item.type }
    }
    return item
  })
}

const useVaultsData = ({
  id,
  type
}: {
  id: string | null
  type: 'vaultList' | 'vaultId'
}): UseVaultsDataResponse => {
  const { t } = useTranslation('vaults')
  const { isAdmin, userContext } = useAuth()
  const [modalActionVault, setModalActionVault] =
    useState<DataModalActionVaultType>({
      isOpen: false,
      action: null,
      id: null
    })
  const [currentVaultType, setCurrentVaultType] = useState<CurrentVaultType>({
    type: type,
    id: id
  })
  const [isInsertedInitialFilters, setIsInsertedInitialFilters] =
    useState(false)
  const [, setCurrentVaultId] = useLocalStorage<CurrentVaultId>(
    'currentVaultId',
    null
  )
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable()
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const { push, back } = useRouter()
  const {
    useDeleteEnvelope,
    usePublishEnvelope,
    useHoldEnvelope,
    useMoveEnvelopeToVault,
    useCancelEnvelope,
    useUnscheduleEnvelope
  } = useEnvelopeApi()
  const { useDeleteTemplate, useMoveTemplate } = useTemplateApi()
  const {
    useVaultOwners,
    useUserAccountVaults,
    useCreateVault,
    useActionFavoriteVault,
    useDeleteVault,
    useUpdateVault,
    useAllAccountUsersVaults,
    useGetVaultsList,
    useGetVault
  } = useVaultApi()
  const {
    data: dataGetVault,
    isError: isErrorGetVault,
    isLoading: isLoadingGetVaultInternal,
    fetchStatus: fetchStatusGetVault
  } = useGetVault(!currentVaultType.id ? null : currentVaultType.id)
  const isLoadingGetVault =
    fetchStatusGetVault === 'fetching' && isLoadingGetVaultInternal
  const {
    filters,
    preparedFilters,
    setPreparedFilters,
    setFilter,
    handleApplyFilters,
    isReady
  } = useTableFilters<FiltersVaultsType>({
    entity: currentVaultType.type === 'vaultId' ? 'all' : null
  })
  const [modalErrorPublishEnvelope, setModalErrorPublishEnvelope] =
    useState<ModalErrorPublishEnvelopeType>({
      open: false,
      error: null
    })

  const fetchAll = useMemo(
    () => isAdmin && filters?.user === 'all',
    [filters?.user, isAdmin]
  )
  const fetchMe = useMemo(
    () =>
      filters?.user === userContext?.userAccountInformation?.id ||
      !filters?.user,
    [filters?.user, userContext]
  )

  const excludeEnvelopes = useMemo(
    () => filters?.entity === 'templates',
    [filters?.entity]
  )
  const excludeTemplates = useMemo(
    () => filters?.entity === 'envelopes',
    [filters?.entity]
  )
  const { data: dataVaultOwners } = useVaultOwners(!!isAdmin)
  const {
    data: dataVaultsList,
    isLoading: isLoadingVaultsList,
    isSuccess: isSuccessVaultsList,
    isFetching: isFetchingVaultsList
  } = useUserAccountVaults(
    {
      query: filters?.query || null,
      typeFavorite: filters?.typeFavorite || null
    },
    fetchMe ? null : (filters?.user as string),
    pageNumber,
    pageSize,
    isInsertedInitialFilters &&
      !fetchAll &&
      currentVaultType.type === 'vaultList'
  )
  const {
    data: dataAllAccountUsersVaultsList,
    isLoading: isLoadingAllAccountUsersVaultsList,
    isSuccess: isSuccessAllAccountUsersVaultsList,
    isFetching: isFetchingAllAccountUsersVaultsList
  } = useAllAccountUsersVaults(
    {
      query: filters?.query || null,
      typeFavorite: filters?.typeFavorite || null
    },
    pageNumber,
    pageSize,
    (fetchAll &&
      isInsertedInitialFilters &&
      currentVaultType.type === 'vaultList') ??
      false
  )
  const {
    data: dataVaultId,
    isLoading: isLoadingVaultId,
    isSuccess: isSuccessVaultId
  } = useGetVaultsList(
    currentVaultType.id,
    pageNumber,
    pageSize,
    excludeEnvelopes,
    excludeTemplates,
    filters?.query && currentVaultType.type === 'vaultId'
      ? filters?.query
      : null,
    currentVaultType.type === 'vaultId' && !!currentVaultType.id
  )
  const {
    mutateAsync: mutateAsyncCreateVault,
    isLoading: isLoadingCreateVault
  } = useCreateVault()
  const {
    mutateAsync: mutateAsyncUpdateVault,
    isLoading: isLoadingUpdateVault
  } = useUpdateVault()
  const {
    mutateAsync: mutateAsyncDeleteVault,
    isLoading: isLoadingDeleteVault
  } = useDeleteVault()
  const { mutateAsync: mutateAsyncFavoriteVault } = useActionFavoriteVault()
  const {
    mutateAsync: mutateAsyncDeleteEnvelope,
    isLoading: isLoadingDeleteEnvelope
  } = useDeleteEnvelope()
  const {
    mutateAsync: mutateAsyncDeleteTemplate,
    isLoading: isLoadingDeleteTemplate
  } = useDeleteTemplate()
  const {
    mutateAsync: mutateAsyncPublishEnvelope,
    isLoading: isLoadingPublishEnvelope
  } = usePublishEnvelope()
  const {
    mutateAsync: mutateAsyncHoldEnvelope,
    isLoading: isLoadingHoldEnvelope
  } = useHoldEnvelope()
  const { mutateAsync: mutateAsyncMoveEnvelopeToVault } =
    useMoveEnvelopeToVault()
  const {
    mutateAsync: mutateAsyncCancelEnvelope,
    isLoading: isLoadingCancelEnvelope
  } = useCancelEnvelope()
  const { mutateAsync: mutateAsyncMoveTemplate } = useMoveTemplate()
  const {
    mutateAsync: mutateAsyncUnscheduleEnvelope,
    isLoading: isLoadingUnscheduleEnvelope
  } = useUnscheduleEnvelope()

  useEffect(() => {
    const applyInitialFilters = async () => {
      if (!isReady || isInsertedInitialFilters) return

      const query = filters?.query || null
      const entity =
        currentVaultType.type === 'vaultId' ? filters?.entity || 'all' : null
      const typeFavorite = filters?.typeFavorite || null

      await setFilter(FiltersKeyVaults.QUERY, query)
      await setFilter(FiltersKeyVaults.ENTITY, entity)
      await setFilter(FiltersKeyVaults.TYPE_FAVORITE, typeFavorite)
      setIsInsertedInitialFilters(true)
    }

    applyInitialFilters()
  }, [
    isReady,
    isInsertedInitialFilters,
    userContext?.userAccountInformation?.id,
    isAdmin,
    dataVaultOwners?.items,
    filters?.user,
    filters?.query,
    filters?.entity,
    filters?.typeFavorite,
    currentVaultType.type,
    setFilter
  ])

  useEffect(() => {
    if (id) {
      setCurrentVaultType({ type: 'vaultId', id })
    }
    if (isErrorGetVault) {
      back()
    }
  }, [back, id, isErrorGetVault])

  const isLoadingUserAccountVaults = useMemo(() => {
    if (currentVaultType.type === 'vaultList') {
      return isLoadingVaultsList || isFetchingVaultsList
    }
    if (currentVaultType.type === 'vaultId') {
      return isLoadingVaultId
    }
    return false
  }, [
    currentVaultType.type,
    isFetchingVaultsList,
    isLoadingVaultId,
    isLoadingVaultsList
  ])

  const currentDataTable = useMemo(() => {
    if (currentVaultType.type === 'vaultId') {
      const newData = {
        ...dataVaultId,
        items: flattenData(dataVaultId?.items)
      } as VaultIdData

      return {
        isLoading: isLoadingVaultId,
        isSuccess: isSuccessVaultId,
        data: newData
      }
    }
    if (currentVaultType.type === 'vaultList') {
      if (filters?.user === 'all') {
        return {
          data: dataAllAccountUsersVaultsList,
          isLoading:
            isLoadingAllAccountUsersVaultsList ||
            isFetchingAllAccountUsersVaultsList,
          isSuccess: isSuccessAllAccountUsersVaultsList
        }
      }
      return {
        data: dataVaultsList,
        isLoading: isLoadingVaultsList || isFetchingVaultsList,
        isSuccess: isSuccessVaultsList
      }
    }
  }, [
    currentVaultType.type,
    dataVaultId,
    isLoadingVaultId,
    isSuccessVaultId,
    filters?.user,
    dataVaultsList,
    isLoadingVaultsList,
    isFetchingVaultsList,
    isSuccessVaultsList,
    dataAllAccountUsersVaultsList,
    isLoadingAllAccountUsersVaultsList,
    isFetchingAllAccountUsersVaultsList,
    isSuccessAllAccountUsersVaultsList
  ])

  const optionsUsers = useCreateOptionsUser({
    users: dataVaultOwners?.items as any[]
  })

  const generateDynamicColumnWidthsListVaults = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      isFavorite: { width: '5%', minWidth: '75px' },
      name: { width: '20%', minWidth: '150px' },
      type: { width: '15%', minWidth: '120px' },
      members: { width: '15%', minWidth: '120px' },
      create: { width: '20%', minWidth: '150px' },
      update: { width: '20%', minWidth: '150px' },
      actions: { width: '5%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidthListVaults = useMemo(() => {
    return generateDynamicColumnWidthsListVaults(breakpoint)
  }, [breakpoint])

  const columnsListVaults: CustomColumn<VaultItemType>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.isFavorite || '',
        accessor: 'isFavorite',
        Cell: ({ row }) => {
          const [isFavorite, setIsFavorite] = useState<boolean>(
            row.original.isFavorite
          )
          const [isLoading, setIsLoading] = useState<boolean>(false)

          const toggleFavorite = async () => {
            const newIsFavorite = !isFavorite
            setIsFavorite(newIsFavorite)

            try {
              setIsLoading(true)
              await mutateAsyncFavoriteVault({
                id: row.original.vaultId,
                action: newIsFavorite ? 'favorite' : 'unfavorite'
              })

              showToast.success(
                newIsFavorite
                  ? t?.toasts?.successFavoriteVault
                  : t?.toasts?.successUnfavoriteVault
              )
            } catch {
              showToast.error(t?.toasts?.errorFavoriteVault)
              setIsFavorite(isFavorite)
            } finally {
              setIsLoading(false)
            }
          }
          return (
            <div className="flex flex-col flex-1">
              <button
                type="button"
                className={`p-4 text-sm font-medium rounded-md text-primary-700`}
                onClick={toggleFavorite}
              >
                {isLoading ? (
                  <CgSpinner className="text-xl text-gray-500 animate-spin" />
                ) : isFavorite ? (
                  <StarIconSolid className="w-5 h-5" />
                ) : (
                  <StarIconOutline className="w-5 h-5" />
                )}
              </button>
            </div>
          )
        },
        ...tableColumnWidthListVaults.isFavorite
      },
      {
        Header: t?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex flex-col flex-1">
              <Link href={`/vaults/${valuesOriginal.vaultId}`}>
                <button className="flex flex-col flex-1 bg-transparent border-none outline-none cursor-pointer">
                  <Typography
                    variant="text-sm-medium"
                    className="hover:text-gray-500"
                  >
                    {valuesOriginal.name}
                  </Typography>
                </button>
              </Link>
            </div>
          )
        },
        ...tableColumnWidthListVaults.name
      },
      {
        Header: t?.tableInformation?.access || '',
        accessor: 'type',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const access = t?.mappingAccess?.[valuesOriginal?.type] || {
            label: '',
            color: '',
            tooltip: ''
          }

          return (
            <div className="flex-1">
              <Badge
                size="sm"
                icon={InformationCircleIcon}
                className={access?.color}
                tooltip={access?.tooltip}
              >
                {access?.label}
              </Badge>
            </div>
          )
        },
        ...tableColumnWidthListVaults.type
      },
      {
        Header: t?.tableInformation?.members || '',
        accessor: 'userAccountMembers',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const avatarMembersGroup = useMemo(() => {
            return valuesOriginal?.userAccountMembers?.map((user: any) => ({
              name: user.name,
              email: user.email,
              imgSrc: user.avatar,
              isActive: user.isActive
            }))
          }, [valuesOriginal?.userAccountMembers])

          return (
            <div className="flex-1">
              <AvatarGroup
                users={avatarMembersGroup}
                max={4}
                infoMessage={t?.infoMessageAvatarGroup}
                type="usersGroup"
              />
            </div>
          )
        },
        ...tableColumnWidthListVaults.members
      },
      {
        Header: t?.tableInformation?.create || '',
        accessor: 'createdByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc ?? '', lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                imgSrc={valuesOriginal.createdByAvatar}
                name={valuesOriginal.createdByName ?? ''}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc ?? '', lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidthListVaults.create
      },
      {
        Header: t?.tableInformation?.update || '',
        accessor: 'updatedByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.updatedByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.updatedAtUtc ?? '', lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                imgSrc={valuesOriginal.updatedByAvatar}
                name={valuesOriginal.updatedByName ?? ''}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.updatedByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.updatedAtUtc ?? '', lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidthListVaults.update
      },
      {
        Header: t?.tableInformation?.actions || '',
        accessor: 'vaultId',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const menu: MenuItem[] = [
            {
              key: 'toEnter',
              label: t?.options?.toEnter
            },
            {
              key: 'view',
              label: t?.options?.view
            },
            {
              key: 'edit',
              label: t?.options?.edit
            },
            {
              key: 'delete',
              label: t?.options?.delete,
              disabled: !valuesOriginal?.canBeRemoved
            }
          ]

          return (
            <Dropdown
              menu={menu}
              onSelect={(state) => {
                if (state.key === 'toEnter') {
                  push(`/vaults/${valuesOriginal.vaultId}`)
                }
                if (state.key === 'view') {
                  setModalActionVault({
                    isOpen: true,
                    action: 'viewVault',
                    id: valuesOriginal.vaultId
                  })
                }
                if (state.key === 'edit') {
                  setModalActionVault({
                    isOpen: true,
                    action: 'updateVault',
                    id: valuesOriginal.vaultId
                  })
                }
                if (state.key === 'delete') {
                  setModalActionVault({
                    isOpen: true,
                    action: 'deleteVault',
                    id: valuesOriginal.vaultId
                  })
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        ...tableColumnWidthListVaults.actions
      }
    ]
  }, [
    lang,
    mutateAsyncFavoriteVault,
    push,
    t?.infoMessageAvatarGroup,
    t?.mappingAccess,
    t?.options?.delete,
    t?.options?.edit,
    t?.options?.toEnter,
    t?.options?.view,
    t?.tableInformation?.access,
    t?.tableInformation?.actions,
    t?.tableInformation?.create,
    t?.tableInformation?.isFavorite,
    t?.tableInformation?.members,
    t?.tableInformation?.name,
    t?.tableInformation?.update,
    t?.toasts?.errorFavoriteVault,
    t?.toasts?.successFavoriteVault,
    t?.toasts?.successUnfavoriteVault,
    tableColumnWidthListVaults.actions,
    tableColumnWidthListVaults.create,
    tableColumnWidthListVaults.isFavorite,
    tableColumnWidthListVaults.members,
    tableColumnWidthListVaults.name,
    tableColumnWidthListVaults.type,
    tableColumnWidthListVaults.update
  ])

  const generateDynamicColumnWidthsVault = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      type: { width: '10%', minWidth: '75px' },
      name: { width: '25%', minWidth: '200px' },
      createdBy: { width: '15%', minWidth: '180px' },
      lastUpdate: { width: '15%', minWidth: '180px' },
      signers: { width: '15%', minWidth: '100px' },
      status: { width: '15%', minWidth: '100px' },
      actions: { width: '5%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1,
      sm: 1,
      xs: 1
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidthVault = useMemo(() => {
    return generateDynamicColumnWidthsVault(breakpoint)
  }, [breakpoint])

  const columnsVault: CustomColumn<FlattenVaultItem>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.type || '',
        accessor: 'type',
        Cell: ({ getValue }) => {
          const value = getValue('type') as 'Envelope' | 'Template'
          const item = t?.mappingTypes?.[value] || {
            label: '',
            color: ''
          }

          return (
            <Badge size="sm" className={item?.color} tooltip={item?.label}>
              {item?.label}
            </Badge>
          )
        },
        ...tableColumnWidthVault.type
      },
      {
        Header: t?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          const href =
            valuesOriginal.type === 'Envelope'
              ? `/envelope/${valuesOriginal.id}/view`
              : `/template/${valuesOriginal.id}`

          return (
            <Link href={href}>
              <div className="w-full whitespace-pre-wrap cursor-pointer">
                <Typography
                  variant="text-sm-medium"
                  className="hover:text-gray-500"
                >
                  {valuesOriginal.name}
                </Typography>
              </div>
            </Link>
          )
        },
        ...tableColumnWidthVault.name
      },
      {
        Header: t?.tableInformation?.createdBy || '',
        accessor: 'createdByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.createdByName}
                imgSrc={valuesOriginal.createdByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidthVault.createdBy
      },
      {
        Header: t?.tableInformation?.lastUpdate || '',
        accessor: 'updatedByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.updatedByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.updatedAtUtc as string, lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.updatedByName as string}
                imgSrc={valuesOriginal.updatedByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.updatedByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.updatedAtUtc as string, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidthVault.lastUpdate
      },
      {
        Header: t?.tableInformation?.signers || '',
        accessor: 'signers',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const signers =
            valuesOriginal.signers?.map((signer: any) => ({
              name: signer.name,
              email: signer.email
            })) || []

          if (signers?.length === 0) {
            return (
              <div className="flex items-center justify-center text-accent-900">
                -
              </div>
            )
          }

          return (
            <div className="flex items-center">
              <AvatarGroup users={signers} />
            </div>
          )
        },
        ...tableColumnWidthVault.signers
      },
      {
        Header: t?.tableInformation?.status || '',
        accessor: 'status',
        Cell: ({ getValue }) => {
          const value = getValue('status')
          const item = t?.mappingStatus?.[value] || {
            label: '',
            color: ''
          }

          return (
            <div
              className={
                !value ? 'flex justify-center items-center text-accent-500' : ''
              }
            >
              {value ? (
                <Badge size="sm" className={item?.color} tooltip={item?.label}>
                  {item?.label}
                </Badge>
              ) : (
                '-'
              )}
            </div>
          )
        },
        ...tableColumnWidthVault.status
      },
      {
        Header: t?.tableInformation?.actions || '',
        accessor: 'id',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const optionsByEnvelopeStatus = {
            [EnvelopeStatus.Draft]: [
              { key: 'view', label: t?.options?.view },
              { key: 'edit', label: t?.options?.edit },
              { key: 'publish', label: t?.options?.publish },
              { key: 'move', label: t?.options?.move },
              { key: 'cancel', label: t?.options?.cancel },
              { key: 'delete', label: t?.options?.delete }
            ],
            [EnvelopeStatus.Hold]: [
              { key: 'view', label: t?.options?.view },
              { key: 'edit', label: t?.options?.edit },
              { key: 'publish', label: t?.options?.publish },
              { key: 'move', label: t?.options?.move },
              { key: 'cancel', label: t?.options?.cancel },
              { key: 'delete', label: t?.options?.delete }
            ],
            [EnvelopeStatus.PublishScheduled]: [
              { key: 'view', label: t?.options?.view },
              { key: 'edit', label: t?.options?.edit },
              { key: 'publish', label: t?.options?.publish },
              {
                key: 'unschedulePublication',
                label: t?.options?.unschedulePublication
              },
              { key: 'move', label: t?.options?.move },
              { key: 'cancel', label: t?.options?.cancel },
              { key: 'delete', label: t?.options?.delete }
            ],
            [EnvelopeStatus.Published]: [
              { key: 'view', label: t?.options?.view },
              { key: 'edit', label: t?.options?.edit },
              { key: 'unpublish', label: t?.options?.unpublish },
              { key: 'move', label: t?.options?.move },
              { key: 'cancel', label: t?.options?.cancel },
              { key: 'delete', label: t?.options?.delete }
            ],
            [EnvelopeStatus.Cancelled]: [
              { key: 'view', label: t?.options?.view },
              { key: 'edit', label: t?.options?.edit },
              { key: 'move', label: t?.options?.move },
              { key: 'delete', label: t?.options?.delete }
            ],
            [EnvelopeStatus.CancelledBySignerMfaError]: [
              { key: 'view', label: t?.options?.view },
              { key: 'edit', label: t?.options?.edit },
              { key: 'move', label: t?.options?.move },
              { key: 'cancel', label: t?.options?.cancel },
              { key: 'delete', label: t?.options?.delete }
            ],
            [EnvelopeStatus.Rejected]: [
              { key: 'view', label: t?.options?.view },
              { key: 'move', label: t?.options?.move },
              { key: 'delete', label: t?.options?.delete }
            ],
            [EnvelopeStatus.Signed]: [
              { key: 'view', label: t?.options?.view },
              { key: 'move', label: t?.options?.move },
              { key: 'delete', label: t?.options?.delete }
            ],
            [EnvelopeStatus.Expired]: [
              { key: 'view', label: t?.options?.view },
              { key: 'edit', label: t?.options?.edit },
              { key: 'move', label: t?.options?.move },
              { key: 'cancel', label: t?.options?.cancel },
              { key: 'delete', label: t?.options?.delete }
            ]
          }

          const menu: MenuItem[] =
            valuesOriginal.type === 'Envelope'
              ? optionsByEnvelopeStatus[valuesOriginal.status as EnvelopeStatus]
              : valuesOriginal.type === 'Template'
                ? [
                    {
                      key: 'createEnvelope',
                      label: t?.options?.createEnvelope
                    },
                    {
                      key: 'edit',
                      label: t?.options?.edit
                    },
                    {
                      key: 'move',
                      label: t?.options?.move
                    },
                    {
                      key: 'rename',
                      label: t?.options?.rename
                    },
                    {
                      key: 'delete',
                      label: t?.options?.delete
                    }
                  ]
                : [
                    {
                      key: 'view',
                      label: t?.options?.view
                    },
                    {
                      key: 'edit',
                      label: t?.options?.edit
                    },
                    { key: 'move', label: t?.options?.move },
                    {
                      key: 'delete',
                      label: t?.options?.delete
                    }
                  ]

          return (
            <Dropdown
              menu={menu}
              onSelect={async (state) => {
                if (state.key === 'view') {
                  if (valuesOriginal.type === 'Envelope') {
                    push(`/envelope/${valuesOriginal.id}/view`)
                  }
                  if (valuesOriginal.type === 'Template') {
                    push(`/template/${valuesOriginal.id}`)
                  }
                }
                if (state.key === 'edit') {
                  if (valuesOriginal?.type === 'Envelope') {
                    if (
                      valuesOriginal?.status === EnvelopeStatus.Published ||
                      valuesOriginal?.status === EnvelopeStatus.PublishScheduled
                    ) {
                      setModalActionVault({
                        isOpen: true,
                        action: 'unpublishToEditEnvelope',
                        id: valuesOriginal?.id
                      })
                      return
                    }
                    if (
                      valuesOriginal?.status ===
                        EnvelopeStatus.CancelledBySignerMfaError ||
                      valuesOriginal?.status === EnvelopeStatus.Expired ||
                      valuesOriginal?.status === EnvelopeStatus.Cancelled
                    ) {
                      await mutateAsyncHoldEnvelope(valuesOriginal?.id)
                      push(`/envelope/${valuesOriginal?.id}/edit`)
                      return
                    }
                    push(`/envelope/${valuesOriginal?.id}/edit`)
                  }
                  if (valuesOriginal?.type === 'Template') {
                    push(`/template/${valuesOriginal?.id}`)
                    return
                  }
                }
                if (state.key === 'rename') {
                  setModalActionVault({
                    isOpen: true,
                    id: valuesOriginal.id,
                    action:
                      valuesOriginal.type === 'Envelope'
                        ? 'renameEnvelope'
                        : 'renameTemplate'
                  })
                }
                if (
                  state.key === 'publish' &&
                  valuesOriginal.type === 'Envelope'
                ) {
                  setModalActionVault({
                    isOpen: true,
                    action: 'publishEnvelope',
                    id: valuesOriginal.id
                  })
                }
                if (
                  state.key === 'unpublish' &&
                  valuesOriginal?.type === 'Envelope'
                ) {
                  setModalActionVault({
                    isOpen: true,
                    action: 'unpublishEnvelope',
                    id: valuesOriginal.id
                  })
                }
                if (
                  state.key === 'cancel' &&
                  valuesOriginal.type === 'Envelope'
                ) {
                  setModalActionVault({
                    isOpen: true,
                    action: 'cancelEnvelope',
                    id: valuesOriginal?.id
                  })
                }
                if (state.key === 'move') {
                  setModalActionVault({
                    isOpen: true,
                    action:
                      valuesOriginal.type === 'Envelope'
                        ? 'moveEnvelope'
                        : 'moveTemplate',
                    id: valuesOriginal?.id
                  })
                }
                if (state.key === 'delete') {
                  if (
                    valuesOriginal.type === 'Envelope' &&
                    valuesOriginal.status === EnvelopeStatus.Published
                  ) {
                    setModalActionVault({
                      isOpen: true,
                      id: valuesOriginal.id,
                      action: 'unpublishToDeleteEnvelope'
                    })
                    return
                  }
                  setModalActionVault({
                    isOpen: true,
                    id: valuesOriginal.id,
                    action:
                      valuesOriginal.type === 'Envelope'
                        ? 'deleteEnvelope'
                        : 'deleteTemplate'
                  })
                }
                if (state.key === 'unschedulePublication') {
                  setModalActionVault({
                    isOpen: true,
                    action: 'unschedulePublication',
                    id: valuesOriginal.id
                  })
                }
                if (state.key === 'createEnvelope') {
                  push(`/envelope?templateId=${valuesOriginal.id}`)
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        ...tableColumnWidthVault.actions
      }
    ]
  }, [
    lang,
    mutateAsyncHoldEnvelope,
    push,
    t?.mappingStatus,
    t?.mappingTypes,
    t?.options?.cancel,
    t?.options?.createEnvelope,
    t?.options?.delete,
    t?.options?.edit,
    t?.options?.move,
    t?.options?.publish,
    t?.options?.rename,
    t?.options?.unpublish,
    t?.options?.unschedulePublication,
    t?.options?.view,
    t?.tableInformation?.actions,
    t?.tableInformation?.createdBy,
    t?.tableInformation?.lastUpdate,
    t?.tableInformation?.name,
    t?.tableInformation?.signers,
    t?.tableInformation?.status,
    t?.tableInformation?.type,
    tableColumnWidthVault.actions,
    tableColumnWidthVault.createdBy,
    tableColumnWidthVault.lastUpdate,
    tableColumnWidthVault.name,
    tableColumnWidthVault.signers,
    tableColumnWidthVault.status,
    tableColumnWidthVault.type
  ])

  const columns = useMemo(() => {
    return currentVaultType.type === 'vaultList'
      ? columnsListVaults
      : columnsVault
  }, [columnsListVaults, columnsVault, currentVaultType.type])

  const onSubmitActionModal = useCallback(
    async (
      values: VaultFormType,
      action: DataModalActionVaultType['action']
    ) => {
      if (action === 'createVault') {
        try {
          await mutateAsyncCreateVault(values as CreateVaultRequest)
          showToast.success(t?.toasts?.successCreateVault)
        } catch (error) {
          showToast.error(t?.toasts?.errorCreateVault)
        } finally {
          setModalActionVault({
            isOpen: false,
            action: null,
            id: null
          })
        }
        return
      }
      if (action === 'updateVault') {
        try {
          await mutateAsyncUpdateVault({
            id: modalActionVault?.id as string,
            ...values
          })
          showToast.success(t?.toasts?.successUpdateVault)
        } catch (error) {
          showToast.error(t?.toasts?.errorUpdateVault)
        } finally {
          setModalActionVault({
            isOpen: false,
            action: null,
            id: null
          })
        }
        return
      }
    },
    [
      modalActionVault?.id,
      mutateAsyncCreateVault,
      mutateAsyncUpdateVault,
      t?.toasts?.errorCreateVault,
      t?.toasts?.errorUpdateVault,
      t?.toasts?.successCreateVault,
      t?.toasts?.successUpdateVault
    ]
  )

  const isLoadingModalConfirm = useMemo(() => {
    return (
      isLoadingDeleteVault ||
      isLoadingDeleteEnvelope ||
      isLoadingDeleteTemplate ||
      isLoadingHoldEnvelope ||
      isLoadingCancelEnvelope ||
      isLoadingUnscheduleEnvelope ||
      isLoadingPublishEnvelope
    )
  }, [
    isLoadingDeleteVault,
    isLoadingDeleteEnvelope,
    isLoadingDeleteTemplate,
    isLoadingHoldEnvelope,
    isLoadingCancelEnvelope,
    isLoadingUnscheduleEnvelope,
    isLoadingPublishEnvelope
  ])

  const handleActionModal = useCallback(
    async (vaultId?: string) => {
      if (modalActionVault.action === 'deleteVault') {
        try {
          await mutateAsyncDeleteVault(modalActionVault?.id as string)
          showToast.success(t?.toasts?.successDeleteVault)
        } catch (error) {
          showToast.error(t?.toasts?.errorDeleteVault)
        }
      }
      if (modalActionVault.action === 'deleteEnvelope') {
        try {
          await mutateAsyncDeleteEnvelope(modalActionVault?.id as string)
          showToast.success(t?.toasts?.successDeleteEnvelope)
        } catch (error) {
          showToast.error(t?.toasts?.errorDeleteEnvelope)
        }
      }
      if (modalActionVault.action === 'deleteTemplate') {
        try {
          await mutateAsyncDeleteTemplate(modalActionVault?.id as string)
          showToast.success(t?.toasts?.successDeleteTemplate)
        } catch (error) {
          showToast.error(t?.toasts?.errorDeleteTemplate)
        }
      }
      if (modalActionVault.action === 'publishEnvelope') {
        try {
          await mutateAsyncPublishEnvelope({
            id: modalActionVault?.id as string
          })
          showToast.success(t?.toasts?.successPublishEnvelope)
        } catch (error: any) {
          if (
            error.response.status === 402 &&
            error.response.statusText === 'Payment Required' &&
            error.response.data
          ) {
            setModalActionVault({
              isOpen: false,
              action: null,
              id: null
            })
            setModalErrorPublishEnvelope({
              open: true,
              error: error.response.data?.shouldBuy
            })

            return
          }
          showToast.error(t?.toasts?.errorPublishEnvelope)
        }
      }
      if (modalActionVault.action === 'unpublishEnvelope') {
        try {
          await mutateAsyncHoldEnvelope(modalActionVault?.id as string)
          showToast.success(t?.toasts?.successUnpublishEnvelope)
        } catch {
          showToast.error(t?.toasts?.errorUnpublishEnvelope)
        }
      }
      if (modalActionVault.action === 'unpublishToEditEnvelope') {
        try {
          await mutateAsyncHoldEnvelope(modalActionVault?.id as string)
          push(`/envelope/${modalActionVault?.id as string}/edit`)
        } catch {
          showToast.error(t?.toasts?.errorUnpublishToEdit)
        }
      }
      if (modalActionVault.action === 'unpublishToDeleteEnvelope') {
        try {
          await mutateAsyncHoldEnvelope(modalActionVault?.id as string)
          await mutateAsyncDeleteEnvelope(modalActionVault?.id as string)
          showToast.success(t?.toasts?.successDeleteEnvelope)
        } catch {
          showToast.error(t?.toasts?.errorDeleteEnvelope)
        }
      }
      if (modalActionVault.action === 'moveEnvelope' && !!vaultId) {
        try {
          await mutateAsyncMoveEnvelopeToVault({
            envelopeId: modalActionVault.id as string,
            vaultId: vaultId as string
          })
          showToast.success(t?.toasts?.successMoveEnvelope)
        } catch {
          showToast.error(t?.toasts?.errorMoveEnvelope)
        }
      }
      if (modalActionVault.action === 'moveTemplate' && !!vaultId) {
        try {
          await mutateAsyncMoveTemplate({
            id: modalActionVault.id as string,
            vaultId: vaultId as string
          })
          showToast.success(t?.toasts?.successMoveTemplate)
        } catch {
          showToast.error(t?.toasts?.errorMoveTemplate)
        }
      }
      if (modalActionVault.action === 'cancelEnvelope') {
        try {
          await mutateAsyncCancelEnvelope(modalActionVault.id as string)
          showToast.success(t?.toasts?.successCancelEnvelope)
        } catch {
          showToast.error(t?.toasts?.errorCancelEnvelope)
        }
      }
      if (modalActionVault.action === 'unschedulePublication') {
        try {
          await mutateAsyncUnscheduleEnvelope(modalActionVault.id as string)
          showToast.success(t?.toasts?.successUnschedulePublication)
        } catch {
          showToast.error(t?.toasts?.errorUnschedulePublication)
        }
      }
      setModalActionVault({
        isOpen: false,
        action: null,
        id: null
      })
    },
    [
      modalActionVault.action,
      modalActionVault.id,
      mutateAsyncCancelEnvelope,
      mutateAsyncDeleteEnvelope,
      mutateAsyncDeleteTemplate,
      mutateAsyncDeleteVault,
      mutateAsyncHoldEnvelope,
      mutateAsyncMoveEnvelopeToVault,
      mutateAsyncMoveTemplate,
      mutateAsyncPublishEnvelope,
      mutateAsyncUnscheduleEnvelope,
      push,
      t?.toasts?.errorCancelEnvelope,
      t?.toasts?.errorDeleteEnvelope,
      t?.toasts?.errorDeleteTemplate,
      t?.toasts?.errorDeleteVault,
      t?.toasts?.errorMoveEnvelope,
      t?.toasts?.errorMoveTemplate,
      t?.toasts?.errorPublishEnvelope,
      t?.toasts?.errorUnpublishEnvelope,
      t?.toasts?.errorUnpublishToEdit,
      t?.toasts?.errorUnschedulePublication,
      t?.toasts?.successCancelEnvelope,
      t?.toasts?.successDeleteEnvelope,
      t?.toasts?.successDeleteTemplate,
      t?.toasts?.successDeleteVault,
      t?.toasts?.successMoveEnvelope,
      t?.toasts?.successMoveTemplate,
      t?.toasts?.successPublishEnvelope,
      t?.toasts?.successUnpublishEnvelope,
      t?.toasts?.successUnschedulePublication
    ]
  )

  const isLoadingSubmitModalAction = useMemo(() => {
    return isLoadingCreateVault || isLoadingUpdateVault
  }, [isLoadingCreateVault, isLoadingUpdateVault])

  const dataCurrentVault = useMemo(() => {
    if (dataGetVault) {
      setCurrentVaultId(dataGetVault?.id)
      return dataGetVault
    }
    return null
  }, [dataGetVault, setCurrentVaultId])

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    optionsUsers,
    modalActionVault,
    setModalActionVault,
    onSubmitActionModal,
    handleActionModal,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    isLoadingUserAccountVaults,
    isLoadingModalConfirm,
    modalErrorPublishEnvelope,
    setModalErrorPublishEnvelope,
    isLoadingSubmitModalAction,
    dataCurrentVault
  }
}

export default useVaultsData
