import { VaultType } from '@/types/global'
import { CustomColumn } from '@/types/react-table-config'
import {
  VaultItemType,
  VaultResponse,
  VaultsAccountsResponse,
  VaultsListResponse
} from '../api/ecm/useVaultApi/useVaultApi.types'
import { ModalErrorPublishEnvelopeType } from '../useEnvelopesData/useEnvelopesData.types'
import { SelectGroupOption, SelectOption } from '@/ui/atoms'

export type MappingStatusType = {
  label: string
  color: string
}

export type VaultFormType = {
  id?: string
  name: string
  description?: string
  type?: VaultType
  userAccountMemberIds?: string[]
}

export type ActionModalVaultType =
  | 'createEnvelope'
  | 'updateEnvelope'
  | 'viewEnvelope'
  | 'deleteEnvelope'
  | 'unpublishEnvelope'
  | 'unpublishToEditEnvelope'
  | 'unpublishToDeleteEnvelope'
  | 'renameEnvelope'
  | 'cancelEnvelope'
  | 'moveEnvelope'
  | 'createTemplate'
  | 'updateTemplate'
  | 'viewTemplate'
  | 'deleteTemplate'
  | 'renameTemplate'
  | 'moveTemplate'
  | 'createVault'
  | 'updateVault'
  | 'viewVault'
  | 'deleteVault'
  | 'unschedulePublication'
  | 'publishEnvelope'
  | null

export type DataModalActionVaultType = {
  isOpen: boolean
  action: ActionModalVaultType
  id: string | null
}

export type FiltersVaultsType = {
  typeFavorite?: 'favorite' | 'unfavorite' | null
  query?: string | null
  user?: string | null
  entity?: 'envelopes' | 'templates' | 'all' | null
}

export enum FiltersKeyVaults {
  USER = 'user',
  QUERY = 'query',
  TYPE_FAVORITE = 'typeFavorite',
  ENTITY = 'entity'
}

export type VaultIdData = Omit<VaultsListResponse, 'items'> & {
  items: FlattenVaultItem[]
}

export type UseVaultsDataResponse = {
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  currentDataTable:
    | {
        data: VaultsAccountsResponse | null | undefined
        isLoading: boolean
        isSuccess: boolean
      }
    | undefined
  columns: CustomColumn<FlattenVaultItem>[]
  optionsUsers: SelectGroupOption[] | SelectOption[]
  onSubmitActionModal: (
    data: VaultFormType,
    action: DataModalActionVaultType['action']
  ) => void
  modalActionVault: DataModalActionVaultType
  setModalActionVault: React.Dispatch<
    React.SetStateAction<DataModalActionVaultType>
  >
  handleActionModal: (vaultId?: string) => Promise<void>
  filters: FiltersVaultsType | null
  preparedFilters: FiltersVaultsType | null
  setPreparedFilters: (filters: FiltersVaultsType) => void
  isLoadingUserAccountVaults: boolean
  handleApplyFilters: (filters: FiltersVaultsType) => void
  isLoadingModalConfirm: boolean
  modalErrorPublishEnvelope: ModalErrorPublishEnvelopeType
  setModalErrorPublishEnvelope: React.Dispatch<
    React.SetStateAction<ModalErrorPublishEnvelopeType>
  >
  isLoadingSubmitModalAction: boolean
  dataCurrentVault: VaultResponse | null
}

export type Signer = {
  name: string
  email: string
}

export type FlattenVaultItem = VaultItemType & {
  name: string
  type?: string
  status?: string
  signers?: Signer[]
  description?: string
}

export type CurrentVaultType = {
  type: 'vaultList' | 'vaultId'
  id: string | null
}
